import { render, staticRenderFns } from "./TabDashboard.vue?vue&type=template&id=b38db546&"
import script from "./TabDashboard.js?vue&type=script&lang=js&"
export * from "./TabDashboard.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TabDashboard.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fuseraccount%2FTabDashboard%2FTabDashboard.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports