import { render, staticRenderFns } from "./ListDetail.vue?vue&type=template&id=43d1e46a&"
import script from "./ListDetail.js?vue&type=script&lang=js&"
export * from "./ListDetail.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./ListDetail.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fcartdetail%2FPageShoppingList%2FListDetail%2FListDetail.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports