import gql from "graphql-tag";

export default {
  name: "SetToken",
  computed: {
    token() {
      return this.$route?.params?.token;
    },
  },
  beforeCreate() {
    this.$store.dispatch("hijackToken", {
      access_token: this.$route?.params?.token,
      token_type: "Bearer",
    });
  },
  apollo: {
    discounts: {
      query: gql`
        query customer {
          q: me {
            customer {
              id
            }
          }
        }
      `,
      update() {
        this.$store.dispatch("setAuthenticated", true);
        this.$router.replace({
          name: "cart",
        });
      },
      error() {
        this.$router.replace({
          name: "cart",
        });
      },
    },
  },
};
