import { render, staticRenderFns } from "./DetailsSection.vue?vue&type=template&id=6dcf5eea&scoped=true&"
import script from "./DetailsSection.js?vue&type=script&lang=js&"
export * from "./DetailsSection.js?vue&type=script&lang=js&"
import style0 from "./DetailsSection.scss?vue&type=style&index=0&id=6dcf5eea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcf5eea",
  null
  
)

/* custom blocks */
import block0 from "./DetailsSection.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fproductdetail%2FDetailsSection%2FDetailsSection.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports