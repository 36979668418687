import gql from "graphql-tag";
import { required } from "vuelidate/lib/validators";
import cartMixin from "@/mixins/cartMixin";
import LoadingButton from "../../common/form/LoadingButton/LoadingButton.vue";
import ServerError from "../../common/form/ServerError/ServerError.vue";
import BaseForm from "../../common/form/BaseForm/BaseForm.vue";
import BaseInput from "../../common/form/BaseInput/BaseInput.vue";
import { locale } from "../../common/shared.js";

export default {
  components: {
    BaseForm,
    BaseInput,
    LoadingButton,
    ServerError,
  },
  data: () => ({
    form: {
      code: null,
    },
    customerDiscounts: [],
    availableDiscounts: [],
  }),
  methods: {
    setDiscount(code) {
      this.form.code = code;
      this.addDiscountCode();
    },
    addDiscountCode() {
      return this.updateMyCart({
        addDiscountCode: {
          code: this.form.code,
        },
      }).then(() => {
        this.form.code = null;
      });
    },
    getErrorMessage({ code }) {
      if (code === "DiscountCodeNonApplicable") {
        return this.$t("nonApplicable");
      }
      return this.$t("unknownError");
    },
  },
  computed: {
    showCustomerDiscounts() {
      return Boolean(this.filteredCustomerDiscounts.length);
    },
    filteredCustomerDiscounts() {
      if (this.me) {
        const used = (
          this.me?.activeCart?.discountCodes || []
        ).map(({ code }) => code);
        return this.availableDiscounts.filter(
          ({ code }) => !used.includes(code)
        );
      }
    },
  },
  mixins: [cartMixin],
  validations: {
    form: {
      code: { required },
    },
  },
  apollo: {
    discounts: {
      query: gql`
        query customer {
          q: me {
            customer {
              id
              custom {
                customFieldsRaw {
                  name
                  value
                }
              }
            }
          }
        }
      `,
      update(result) {
        this.customerDiscounts = (
          result?.q.customer?.custom?.customFieldsRaw || []
        )
          .filter(
            ({ name }) => name === "availableDiscountCodes"
          )
          .flatMap(({ value }) => value);
      },
      skip() {
        return !this.$store.state.authenticated;
      },
    },
    available: {
      query: gql`
        query codes($where: String!, $locale: Locale!) {
          discountCodes(where: $where) {
            results {
              id
              name(locale: $locale)
              code
            }
          }
        }
      `,
      variables() {
        const codes = this.customerDiscounts
          .map((code) => `"${code}"`)
          .join(",");
        return {
          where: `code in (${codes}) and isActive=true`,
          locale: locale(this),
        };
      },
      skip() {
        return !this?.customerDiscounts?.length;
      },
      update(result) {
        this.availableDiscounts =
          result?.discountCodes?.results || [];
      },
    },
  },
};
