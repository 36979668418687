import { render, staticRenderFns } from "./TabReturn.vue?vue&type=template&id=0dcc5e97&"
import script from "./TabReturn.js?vue&type=script&lang=js&"
export * from "./TabReturn.js?vue&type=script&lang=js&"
import style0 from "./TabReturn.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./TabReturn.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fuseraccount%2FTabReturn%2FTabReturn.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports