import { render, staticRenderFns } from "./LoginButton.vue?vue&type=template&id=51b88310&"
import script from "./LoginButton.js?vue&type=script&lang=js&"
export * from "./LoginButton.js?vue&type=script&lang=js&"
import style0 from "./LoginButton.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./LoginButton.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fheader%2FLoginButton%2FLoginButton.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports