import { render, staticRenderFns } from "./PageStoreLocator.vue?vue&type=template&id=b08f9686&"
import script from "./PageStoreLocator.js?vue&type=script&lang=js&"
export * from "./PageStoreLocator.js?vue&type=script&lang=js&"
import style0 from "./PageStoreLocator.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PageStoreLocator.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Fstores%2FPageStoreLocator%2FPageStoreLocator.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports