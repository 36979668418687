import { render, staticRenderFns } from "./minicart-template.html?vue&type=template&id=4559c622&scoped=true&"
import script from "./BasePrice.js?vue&type=script&lang=js&"
export * from "./BasePrice.js?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=4559c622&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4559c622",
  null
  
)

export default component.exports