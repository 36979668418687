import { locale } from "../../shared";
import RemoveDiscountCodeForm from "../RemoveDiscountCodeForm/RemoveDiscountCodeForm.vue";
import config from "../../../../../sunrise.config";

export default {
  components: { RemoveDiscountCodeForm },
  props: {
    cartLike: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    translated(localized) {
      return (
        localized[locale(this)] ||
        localized[Object.keys(config.languages)[0]]
      );
    },
  },
};
