import { render, staticRenderFns } from "./BasePrice.vue?vue&type=template&id=1af96e41&scoped=true&"
import script from "./BasePrice.js?vue&type=script&lang=js&"
export * from "./BasePrice.js?vue&type=script&lang=js&"
import style0 from "./style.css?vue&type=style&index=0&id=1af96e41&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af96e41",
  null
  
)

export default component.exports