import { render, staticRenderFns } from "./PageLogin.vue?vue&type=template&id=5f6caaa5&"
import script from "./PageLogin.js?vue&type=script&lang=js&"
export * from "./PageLogin.js?vue&type=script&lang=js&"
import style0 from "./PageLogin.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PageLogin.txt?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fcomponents%2Flogin%2FPageLogin%2FPageLogin.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports